import React, { useState } from "react";
import {
  CloseIcon,
  Dialog,
  Dropdown,
  Input,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import "../index.scss";
import axios from "axios";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";

const UploadSingleParentModel = (props) => {
  const [parentDetails, setParentDetails] = useState({
    name: "",
    email: "",
    provider: "",
    number: NaN,
    expirationDate: "",
  });
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const providers = ["Microsoft", "Google"];

  const { t } = useTranslation();

  const parentDialog = t("setting").parent.parentDialog;

  const selectedProvider = providers.find(
    (provider) =>
      provider.toLowerCase() === parentDetails.provider.toLowerCase()
  );

  const handleAddParent = async () => {
    if (
      !!parentDetails.name.trim() &&
      !!parentDetails.email.trim() &&
      !!parentDetails.provider.trim() &&
      !!parentDetails.number.trim() &&
      !!parentDetails.expirationDate &&
      selectedStudents.length
    ) {
      setIsLoading(true);
      const payload = {
        parentData: {
          parentName: parentDetails.name,
          parentEmail: parentDetails.email,
          number: parentDetails.number,
          expirationDate: parentDetails.expirationDate,
          provider: parentDetails.provider,
        },
        students: selectedStudents.map((student) => student.key),
      };
      try {
        let config = {
          method: "post",
          url: `${process.env.REACT_APP_EP_URL}/api/parents${props.user.slug}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + props.user.accessToken,
          },
          data: payload,
        };

        const response = await axios.request(config);
        setIsLoading(false);
        if (response.data.success) {
          props.getAllParents();
          setSelectedStudents([]);
          setParentDetails({
            name: "",
            email: "",
            provider: "",
            number: NaN,
            expirationDate: "",
          });
          props.onClose();
        } else {
          const failedParent = response.data;
          const errorMessage =
            failedParent.isExist && failedParent.role
              ? `This user already exists with a ${failedParent.role} role in your school.`
              : "Parent Adding Failed!";
          toast.error(errorMessage);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        toast.error("Parent Adding Failed!");
      }
    } else {
      setShowError(true);
    }
  };

  const handleReset = () => {
    setParentDetails({
      name: "",
      email: "",
      provider: "",
      number: NaN,
      expirationDate: "",
    });
    setSelectedStudents([]);
    setShowError(false);
  };

  return (
    <Dialog
      open={props.isOpen}
      content={
        <div className="upload-single-parent-model-content">
          <div style={{ width: "100%" }}>
            <p className="sub-title">{parentDialog.parentName.title}</p>
            <Input
              placeholder={parentDialog.parentName.placeholder}
              name="parentName"
              className="parent-input"
              value={parentDetails.name}
              onChange={(e) => {
                setParentDetails({ ...parentDetails, name: e.target.value });
              }}
              status={
                showError ? (!parentDetails.name?.length ? "error" : "") : ""
              }
              style={{ width: "100%" }}

              // disabled={isLoading}
            />
            {showError ? (
              !parentDetails.name?.length ? (
                <p style={{ color: "red", fontSize: "13px" }}>
                  {parentDialog.parentName.validationMsg}
                </p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
          <div style={{ width: "100%" }}>
            <p className="sub-title">{parentDialog.parentEmail.title}</p>
            <Input
              placeholder={parentDialog.parentEmail.placeholder}
              name="parentEmail"
              className="parent-input"
              value={parentDetails.email}
              onChange={(e) => {
                setParentDetails({ ...parentDetails, email: e.target.value });
              }}
              status={
                showError ? (!parentDetails.name?.length ? "error" : "") : ""
              }
              // disabled={isLoading}
              style={{ width: "100%" }}
            />
            <p style={{ color: "orange", fontSize: "13px", paddingTop: "5px" }}>
              {parentDialog.parentEmail.warningMsg}
            </p>
            {showError ? (
              !parentDetails.email?.length ? (
                <p style={{ color: "red", fontSize: "13px" }}>
                  {parentDialog.parentEmail.validationMsg}
                </p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
          <div style={{ width: "100%" }}>
            <p className="sub-title">{parentDialog.parentNumber.title}</p>
            <Input
              placeholder={parentDialog.parentNumber.placeholder}
              name="parentNumber"
              type="number"
              className="parent-input"
              value={parentDetails.number}
              onChange={(e) => {
                setParentDetails({ ...parentDetails, number: e.target.value });
              }}
              status={
                showError ? (!parentDetails.number?.length ? "error" : "") : ""
              }
              // disabled={isLoading}
              style={{ width: "100%" }}
            />
            {showError ? (
              !parentDetails.number?.length ? (
                <p style={{ color: "red", fontSize: "13px" }}>
                  {parentDialog.parentNumber.validationMsg}
                </p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
          <div style={{ width: "100%" }}>
            <p className="sub-title">{parentDialog.expiryDate.title}</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={
                  parentDetails.expirationDate
                    ? moment(parentDetails.expirationDate)
                    : null
                }
                disablePast
                disabled={isLoading}
                onChange={(v) => {
                  const date = moment.utc(moment(v.$d).format("YYYY-MM-DD"));
                  setParentDetails({ ...parentDetails, expirationDate: date });
                }}
                PopperProps={{
                  // disablePortal: true,
                  sx: { zIndex: 15000 },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    disabled={isLoading}
                    inputProps={{ style: { fontSize: 13 } }}
                    error={showError && !parentDetails.expirationDate}
                  />
                )}
              />
            </LocalizationProvider>
            {showError ? (
              !parentDetails.expirationDate ? (
                <p style={{ color: "red", fontSize: "13px" }}>
                  {parentDialog.expiryDate.validationMsg}
                </p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
          <div>
            <p className="sub-title">{parentDialog.students.title}</p>

            {props.studentsWithoutParents.length > 0 && (
              <Dropdown
                disabled={false}
                search
                multiple
                fluid
                items={props.studentsWithoutParents}
                placeholder={parentDialog.students.placeholder}
                value={selectedStudents}
                defaultValue={selectedStudents}
                getA11ySelectionMessage={{
                  onAdd: (item) => {
                    let clonedSelectedStudents = [...selectedStudents];
                    clonedSelectedStudents.push(item);
                    setSelectedStudents(clonedSelectedStudents);
                    let clonedStudentsWithoutParents =
                      props.studentsWithoutParents.filter(
                        (s) => s?.key && s.key !== item.key
                      );
                    props.setStudentsWithoutParents(
                      clonedStudentsWithoutParents
                    );
                  },
                  onRemove: (item) => {
                    const updatedlist = selectedStudents.filter(
                      (s) => s?.key && s.key !== item.key
                    );
                    let clonedStudentsWithoutParents = [
                      item,
                      ...props.studentsWithoutParents,
                    ];
                    clonedStudentsWithoutParents.push(item);
                    props.setStudentsWithoutParents(
                      clonedStudentsWithoutParents
                    );
                    setSelectedStudents(updatedlist);
                  },
                }}
                noResultsMessage={"No results"}
                className="select-students-dropdown"
              />
            )}
            {showError ? (
              selectedStudents.length === 0 ? (
                <p style={{ color: "red", fontSize: "13px" }}>
                  {parentDialog.students.validationMsg}
                </p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
          <div>
            <p className="sub-title">{parentDialog.provider.title}</p>

            {providers.length > 0 && (
              <Dropdown
                disabled={false}
                // multiple
                fluid
                items={providers}
                placeholder={parentDialog.provider.placeholder}
                value={selectedProvider}
                onChange={(e, { value }) => {
                  setParentDetails({
                    ...parentDetails,
                    provider: value.toLowerCase(),
                  });
                }}
                noResultsMessage={"No results"}
                className="select-provider-dropdown"
              />
            )}
            {showError ? (
              !parentDetails.provider.trim() ? (
                <p style={{ color: "red", fontSize: "13px" }}>
                  {parentDialog.provider.validationMsg}
                </p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
        </div>
      }
      header={parentDialog.addParentTitle}
      className="smallDlg"
      confirmButton={
        isLoading
          ? {
              text: parentDialog.addButton,
              disabled: true,
              loading: true,
            }
          : parentDialog.addButton
      }
      cancelButton={
        isLoading
          ? { text: parentDialog.cancelButton, disabled: true }
          : parentDialog.cancelButton
      }
      onConfirm={handleAddParent}
      onCancel={() => {
        props.onClose();
        handleReset();
      }}
      headerAction={{
        icon: <CloseIcon />,
        title: "Close",
        onClick: () => {
          props.onClose();
          handleReset();
        },
        disabled: isLoading,
      }}
    />
  );
};

export default UploadSingleParentModel;
